import React from 'react';

import ComingSoon from './pages/ComingSoon';

function App() {
  return (
    <ComingSoon/>
  );
}

export default App;
