import React from 'react';

import cover from '../assets/img/cover.svg';
import logo from '../assets/img/logo_white.svg';

export default function ComingSoon() {
  return (
    <div className="relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center" style={{
      backgroundImage: `url(${cover})`
    }}>
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-25"></div>
  
      <div className="z-50 flex flex-col justify-center items-center text-white w-full h-screen px-4">
        <img className="w-full sm:max-w-2xl" src={ logo } alt="GOALL" />
        <h2 className="text-xl sm:text-4xl mt-4">Decentralised sport</h2>
        <h4 className="text-base sm:text-xl mt-4">Stay tuned for something amazing!</h4>
        <p className="mt-4">Contact us: <a href="mailto:contact@goall.io">contact@goall.io</a></p>
      </div>
  
    </div>

  );
}
